import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import { useTranslation } from "react-i18next";

// Import projects data
import { projectsData } from "./ProjectsData";

function Projects() {
  const { t ,i18n} = useTranslation();

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t("projects.title")} <strong className="purple">{t("projects.title2")}</strong>
        </h1>
        <p style={{ color: "white" }}>{t("projects.des")}</p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {projectsData.map((project, index) => (
            <Col md={4} className="project-card" key={index}>
              <ProjectCard
                imgPaths={project.imgPaths}
                title={project.title}
                description={t(project.descriptionKey)}
                status={t(project.statusKey)}
                androidUrl={project.androidUrl}
                iosUrl={project.iosUrl}
                liveUrl={project.liveUrl}
                direction={i18n.dir()}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
