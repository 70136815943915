import React, { useState, useRef } from "react";
import { Card, Carousel, Modal, Button } from "react-bootstrap";
import "./ProjectCard.css";
import { useTranslation } from "react-i18next";
import web from "../../Assets/chrome.png"; // Import the image
import playStore from "../../Assets/playstore.png"; // Import the image
import appStore from "../../Assets/app-store.png"; // Import the image
import { AiOutlineClose } from "react-icons/ai"; // Import the X icon from react-icons

function ProjectCard({ imgPaths, title, description, status, iosUrl, androidUrl, liveUrl, direction }) {
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation();
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const imageRef = useRef(null);

  const handleShow = (index) => {
    setCurrentIndex(index);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imgPaths.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imgPaths.length - 1 : prevIndex - 1
    );
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX && touchEndX) {
      const swipeDistance = touchEndX - touchStartX;

      // Determine if the swipe is significant enough to change the image
      if (swipeDistance > 50) {
        handlePrev(); // Swipe right for previous
      } else if (swipeDistance < -50) {
        handleNext(); // Swipe left for next
      }
    }

    // Reset touch positions
    setTouchStartX(null);
    setTouchEndX(null);
  };

  const isRTL = direction === "rtl";
  return (
    <>
      <Card className={`project-card-view ${isRTL ? "rtl" : ""}`}>
        <Carousel>
          {imgPaths.map((imgPath, index) => (
            <Carousel.Item key={index}>
              <Card.Img
                variant="top"
                src={imgPath}
                alt={`slide ${index}`}
                onClick={() => handleShow(index)}
                style={{ cursor: "pointer", height: "450px", width: "100%", objectFit: "cover" }}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <Card.Body style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <div>
            <Card.Title className="card-title">{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
          </div>
          <div className={`project-card-footer ${status === t("prevprojects.statuspublished") ? "center-icons" : ""}`}>
            {status === t("prevprojects.statuspublished") ? (
              <>
                {/* Live URL Button */}
                {liveUrl && (
                  <a
                    href={liveUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderRadius: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="icon-container">
                      <div className="icon-circle">
                        <img
                          src={web} // Use the imported image
                          alt="Live Icon"
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    </div>
                  </a>
                )}

                {/* Android URL Button */}
                {androidUrl ? (
                  <a
                    href={androidUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderRadius: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="icon-container">
                      <div className="icon-circle">
                        <img
                          src={playStore} // Use the imported image
                          alt="Play Store Icon"
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    </div>
                  </a>
                ) : !liveUrl && (
                  <div className="icon-container">
                    <div className="icon-circle">
                      <img
                        src={playStore} // Use the imported image
                        alt="Play Store Icon"
                        style={{ width: "24px", height: "24px", opacity: 0.5 }} // Dim the icon
                      />
                    </div>
                    <p className={`coming-soon-label ${isRTL ? 'rtl' : ''}`}>
                      {t("prevprojects.statuscomingsoon")}
                    </p>
                  </div>
                )}

                {/* iOS URL Button */}
                {iosUrl ? (
                  <a
                    href={iosUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      borderRadius: '20px',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="icon-container">
                      <div className="icon-circle">
                        <img
                          src={appStore} // Use the imported image
                          alt="App Store Icon"
                          style={{ width: "24px", height: "24px" }}
                        />
                      </div>
                    </div>
                  </a>
                ) : !liveUrl && (
                  <div className="icon-container">
                    <div className="icon-circle">
                      <img
                        src={appStore} // Use the imported image
                        alt="App Store Icon"
                        style={{ width: "24px", height: "24px", opacity: 0.5 }} // Dim the icon
                      />
                    </div>
                    <p className={`coming-soon-label ${isRTL ? 'rtl' : ''}`}>
                      {t("prevprojects.statuscomingsoon")}
                    </p>
                  </div>
                )}
              </>
            ) : (
              <p style={{ textAlign: 'center' }}>
                <strong>{t("prevprojects.statustitle")}:</strong> {status}
              </p>
            )}
          </div>
        </Card.Body>
      </Card>

     {/* Modal */}
     <Modal show={show} onHide={handleClose} centered className="custom-modal">
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <Button variant="secondary" onClick={handlePrev}>&lt;</Button>
          <div style={{ position: "relative" }}>
            <img
              ref={imageRef}
              src={imgPaths[currentIndex]}
              alt="Large view"
              className="enlarged-image"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              style={{ touchAction: "none" }} // Prevent default touch behavior
            />
            {/* X Icon */}
            <AiOutlineClose
              onClick={handleClose}
              className="close-icon" // Apply the CSS class
            />
          </div>
          <Button variant="secondary" onClick={handleNext}>&gt;</Button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectCard;