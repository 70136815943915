// ProjectsData.js

// Manager imports
import employeeList from "../../Assets/ManagerProject/employee list.png";
import homePage2 from "../../Assets/ManagerProject/home page 2.png";
import homePage from "../../Assets/ManagerProject/home page.png";
import workSchedulePage from "../../Assets/ManagerProject/work schedule page.png";

// Riyda app imports
import login from "../../Assets/RiydaApp/login.png";
import map from "../../Assets/RiydaApp/map.png";
import paying from "../../Assets/RiydaApp/paying.png";
import serviceInfo from "../../Assets/RiydaApp/service info.png";
import services from "../../Assets/RiydaApp/services.png";
import sidebar from "../../Assets/RiydaApp/sidebar.png";

// Tmrdparts imports
import aboutUs from "../../Assets/Tmrdparts/about us.png";
import contactUs from "../../Assets/Tmrdparts/contact us.png";
import Homepage from "../../Assets/Tmrdparts/homepage.png";
import Homepage2 from "../../Assets/Tmrdparts/homepage2.png";
import Homepage3 from "../../Assets/Tmrdparts/homepage3.png";
import pickingTruckModel from "../../Assets/Tmrdparts/picking truck model.png";
import productsPage from "../../Assets/Tmrdparts/products page.png";

// CoWallet imports
import Add_coupon from "../../Assets/CoWalletApp/Add_coupon.png";
import Category from "../../Assets/CoWalletApp/Cate_choice.png";
import CouponPage from "../../Assets/CoWalletApp/Coupon_Page.png";
import Homepage_Co from "../../Assets/CoWalletApp/Home_page.png";
import Qr from "../../Assets/CoWalletApp/Qr.png";

// Lista imports
import Drawer_Lista from "../../Assets/ListaApp/Drawer_Ar.png";
import Home_Ar from "../../Assets/ListaApp/Home_Ar.png";
import Home_En from "../../Assets/ListaApp/Home_En.png";
import List_Added_En from "../../Assets/ListaApp/List_Added_En.png";
import List_En from "../../Assets/ListaApp/List_En.png";
import Search_Ar from "../../Assets/ListaApp/Search_Ar.png";

// Project details
export const projectsData = [
  {
    title: "Tmrdparts Website",
    descriptionKey: "prevprojects.tmrdparts",
    statusKey: "prevprojects.statuspublished",
    iosUrl:null,
    androidUrl:null,
    liveUrl: "https://tmrdparts.com",
    imgPaths: [Homepage, aboutUs, contactUs, Homepage2, Homepage3, pickingTruckModel, productsPage],
  },
  {
    title: "CoWallet App",
    descriptionKey: "prevprojects.cowallet",
    statusKey: "prevprojects.statuspublished",
    iosUrl:null,
    androidUrl:"https://play.google.com/store/apps/details?id=com.Vynix.copouns_wallet_app",
    liveUrl: null, // Update to the actual live URL
    imgPaths: [Homepage_Co,Category, Add_coupon, CouponPage, Qr],
  },
  {
    title: "Lista App",
    descriptionKey: "prevprojects.lista",
    statusKey: "prevprojects.statuspublished",
    iosUrl:null,
    androidUrl:"https://play.google.com/store/apps/details?id=com.vynix.listapp",
    liveUrl: null, // Update to the actual live URL
    imgPaths: [Home_En, Home_Ar,Drawer_Lista , List_Added_En, List_En, Search_Ar],
  },
  {
    title: "Riyda App",
    descriptionKey: "prevprojects.riydaapp",
    statusKey: "prevprojects.statuscomingsoon",
    iosUrl:null,
    androidUrl:null,
    liveUrl: null,
    imgPaths: [map, services, sidebar, paying, login, serviceInfo],
  },
  {
    title: "Manager System",
    descriptionKey: "prevprojects.managersystem",
    statusKey: "prevprojects.statusprivate",
    iosUrl:null,
    androidUrl:null,
    liveUrl: null,
    imgPaths: [homePage, employeeList, homePage2, workSchedulePage],
  },


];
