import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myVideo from "../../Assets/vynix.mp4"; // Import your video file
import Tilt from "react-parallax-tilt";
import { useTranslation } from "react-i18next";
import "./Home2.css"; // Import your CSS file

function Home2() {
  const { t } = useTranslation();
  const [isMuted, setIsMuted] = useState(true); // State to manage mute
  const [isFullScreen, setIsFullScreen] = useState(false); // State to track full-screen mode
  const [wasPlaying, setWasPlaying] = useState(false); // State to track if the video was playing before full-screen
  const videoRef = useRef(null); // Ref for the video element

  // Function to toggle mute
  const toggleMute = () => {
    setIsMuted((prev) => !prev);
  };

  // Function to toggle full-screen mode
  const toggleFullScreen = async () => {
    if (videoRef.current) {
      if (!document.fullscreenElement) {
        // Store the current playback state
        setWasPlaying(!videoRef.current.paused);

        // Enter full-screen mode
        await videoRef.current.requestFullscreen();

        // Lock the screen orientation (if supported)
        if (window.screen.orientation && window.screen.orientation.lock) {
          try {
            const currentOrientation = window.screen.orientation.type;
            await window.screen.orientation.lock(currentOrientation);
          } catch (error) {
            console.warn("Screen orientation locking not supported:", error);
          }
        }
      } else {
        // Exit full-screen mode
        await document.exitFullscreen();

        // Unlock the screen orientation (if supported)
        if (window.screen.orientation && window.screen.orientation.unlock) {
          await window.screen.orientation.unlock();
        }
      }
    }
  };

  // Listen for full-screen change events
  useEffect(() => {
    const handleFullScreenChange = () => {
      const isFullScreenNow = !!document.fullscreenElement;
      setIsFullScreen(isFullScreenNow);

      if (!isFullScreenNow && videoRef.current) {
        // Restore the playback state after exiting full-screen
        if (wasPlaying) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [wasPlaying]);

  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em", textAlign: "right" }}>
              {t("homeabout.title")}
            </h1>
            <p className="home-about-body">
              {t("homeabout.line1")}
              <br />
              <i>
                <b className="purple">{t("homeabout.line2")}</b>
              </i>
              <br />
              {t("homeabout.line3")}
              <br />
              {t("homeabout.line4")}
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <div style={{ position: "relative" }}>
                <video
                  ref={videoRef} // Attach the ref
                  autoPlay
                  loop
                  muted={isFullScreen ? false : isMuted} // Unmute in full-screen mode
                  onClick={isFullScreen ? undefined : toggleMute} // Toggle mute only outside full-screen
                  className="img-fluid video-player" // Add a class for styling
                  style={{ borderRadius: "10px", cursor: "pointer" }} // Add cursor pointer
                >
                  <source src={myVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {/* Full-Screen Button with YouTube-style expand icon */}
                <button
                  onClick={toggleFullScreen}
                  style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    border: "none",
                    borderRadius: "50%",
                    padding: "5px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "32px",
                    height: "32px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    width="20px"
                    height="20px"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                  </svg>
                </button>
              </div>
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;